import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { RiExternalLinkLine, RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      width: 0,
      height: 0,
      ratioWidth: 0,
      ratioHeight: 0,
      percentWidth: 0,
      percentHeight: 0,
      inputUrl: '',
      inputWidth: 0,
      inputHeight: 0,
      pixelRatio: 0,
      firstOpen: false
    }

    this.copy = 2022;
    this.today = new Date();

    this.inputChange = this.inputChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.openBrowser = this.openBrowser.bind(this);
  }

  componentDidMount(){
    this.handleResize();

    this.setState({ pixelRatio: window.devicePixelRatio || 1 });

    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState){}

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  inputChange(event){
    this.setState({ [event.target.name]: event.target.value });
  }

  handleResize(){
    const { innerWidth: width, innerHeight: height } = window;
    
    let num = width,
        widthRatio = width,
        heightRatio = height,
        widthPercent = 0,
        heightPercent = 0;

    for(num; num > 1; num--) {
      if((widthRatio % num) === 0 && (heightRatio % num) === 0) {
        widthRatio = widthRatio / num;
        heightRatio = heightRatio / num;
      }
    }

    if(widthRatio > heightRatio){
      widthPercent = 100;
      heightPercent = ((heightRatio / widthRatio) * 100).toFixed(2);

    }else{
      heightPercent = 100;
      widthPercent = ((widthRatio / heightRatio) * 100).toFixed(2);
    }
    
    if(this.state.firstOpen){
      this.setState({
        width: width,
        height: height,
        ratioWidth: widthRatio,
        ratioHeight: heightRatio,
        percentWidth: widthPercent,
        percentHeight: heightPercent,
      });
    }else{
      this.setState({
        width: width,
        height: height,
        ratioWidth: widthRatio,
        ratioHeight: heightRatio,
        percentWidth: widthPercent,
        percentHeight: heightPercent,
        inputWidth: width,
        inputHeight: height,
        firstOpen: true
      });
    }
  }

  openBrowser(){
    const height = parseInt(this.state.inputHeight) + 28,
          url = this.state.inputUrl || '/';

    window.open(
      url,
      'targetWindow',
      `
      toolbar=no,
      titlebar=no,
      fullscreen=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=` + this.state.inputWidth + `,
      height=` + height + `
      `
    );
  }
  
  render(){
    return (
      <>
        <Helmet>
          <title>ScreenSize {`${this.state.width} x ${this.state.height}`}</title>
        </Helmet>

        <header>
          <ul>
            <li>
              <a href="/">
                <Logo />
              </a>
              <ul>
                <li>
                  <label>URL</label>
                  <input type="text" name="inputUrl" placeholder="URL" value={this.state.inputUrl} onChange={this.inputChange} className="no-unit" />
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <label>Width</label>
                  <input type="text" name="inputWidth" placeholder="Width" value={this.state.inputWidth} onChange={this.inputChange} />
                  <span className="unit">px</span>
                </li>
                <li>
                  <label>Height</label>
                  <input type="text" name="inputHeight" placeholder="Height" value={this.state.inputHeight} onChange={this.inputChange} />
                  <span className="unit">px</span>
                </li>
                <li>
                  <button type="button" onClick={this.openBrowser}>
                    Open<RiExternalLinkLine />
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </header>

        <ul className="screen-mid">
          <li>
            <div>Device Pixel Ratio</div>
            <ul>
              <li className="lite">
                {this.state.pixelRatio}x
              </li>
            </ul>
          </li>
          <li>
            <div>Aspect Ratio</div>
            <div className="simulation">
              <div style={{width: this.state.percentWidth + '%', height: this.state.percentHeight + '%'}}>
                <span>
                  {this.state.ratioWidth} : {this.state.ratioHeight}
                  <strong>
                    {this.state.percentWidth === 100 ? '1' : (this.state.percentWidth / 100).toFixed(2)}
                    &nbsp;:&nbsp;
                    {this.state.percentHeight === 100 ? '1' : (this.state.percentHeight / 100).toFixed(2)}
                  </strong>
                </span>
              </div>
            </div>
          </li>
          <li>
            <div>Browser Screen Size</div>
            {this.state.pixelRatio === 1 ? (
              <ul>
                <li>
                  {this.state.width}
                  <span>Pixel / DPI</span>
                </li>
                <li>
                  x
                  <span>&nbsp;</span>
                </li>
                <li>
                  {this.state.height}
                  <span>Pixel / DPI</span>
                </li>
              </ul>
            ) : (
              <>
                <ul>
                  <li>
                    {this.state.width}
                    <span>Pixel</span>
                  </li>
                  <li>
                    x
                    <span>&nbsp;</span>
                  </li>
                  <li>
                    {this.state.height}
                    <span>Pixel</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    {this.state.width * this.state.pixelRatio}
                    <span>DPI</span>
                  </li>
                  <li>
                    x
                    <span>&nbsp;</span>
                  </li>
                  <li>
                    {this.state.height * this.state.pixelRatio}
                    <span>DPI</span>
                  </li>
                </ul>
              </>
            )}
          </li>
        </ul>

        <footer>
          {/* Copyrights &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} <a href="/">ScreenSize</a> by <a href="nabilamerthabit.com" target="_blank" >Nabil Amer Thabit</a>. All Rights Reserved. */}
          <a href="/">ScreenSize</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
        </footer>
      </>
    );
  }
}

export default App;
